import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import Banner from "../components/hero/banner.js"
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"))
)

class ProcurementPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Banner />}
      >
        <Seo
          title="Procurement Automation Services | Quandary Consulting Group"
          description="We use low-code to automate your procurement processes, significantly reducing the processing time and cost of each invoice. Learn more."
        />
        <LazyLoadComponent>
          <PartnerSwiper />
        </LazyLoadComponent>
        <section style={{ padding: "3rem 0", backgroundColor: "#e7e7e7" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 text-justify text-left-mobile">
                <div className="intro mb-4">
                  <h2 className="mt-0">
                    Go Beyond Cost-Savings
                    <br />
                    with Strategic Procurement
                  </h2>
                </div>
                <p className="mt-3">
                  Streamlining sourcing and purchasing is about more than cost
                  reduction. While reducing operational costs is key, a
                  digitized procurement process gives your organization the
                  edge. You’ll gain real-time, actionable insights into your
                  supply chain, improve agility, and build stronger supplier
                  relationships that benefit your organization.
                </p>
                <p>
                  Whether you’re looking to improve a single system or you need
                  to overhaul your entire procurement process, Quandary
                  Consulting Group can help. Our team of experts will reduce the
                  headaches, frustration, and waste in your procurement process.
                  Using low code, we connect disparate systems and automate
                  tasks that dramatically reduce inefficiencies across your
                  entire organization. It’s enterprise-level efficiency without
                  the enterprise-level price-tag.
                </p>

                <div className="text-left my-2">
                  <Link
                    className="btn btn-primary btn-arrow mt-3"
                    to="/contact/"
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 pt-5">
                <LazyLoadComponent>
                  <div className="embed-container">
                    <iframe
                      src="https://www.youtube.com/embed/1itK51cGqRU?controls=0&amp;rel=0"
                      title="youtubeEmbed"
                      frameborder="0"
                      modestbranding
                      allowfullscreen
                    ></iframe>
                  </div>
                </LazyLoadComponent>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white quandary-connect-glance"
          style={{ padding: "3rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Why Work with Procurement Consultants</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="../images/satisfied.png"
                  alt="Expertise"
                  layout="constrained"
                  style={{ width: "60px", maxWidth: "60px" }}
                  loading="lazy"
                />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Fewer <br />
                  Frustrations
                </p>
                <p>
                  Reduce the risks, errors, and waste associated with
                  procurement and sourcing.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="../images/data.png"
                  alt="Expertise"
                  layout="constrained"
                  style={{ width: "60px", maxWidth: "60px" }}
                  loading="lazy"
                />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Enhanced <br />
                  Data
                </p>
                <p>
                  Discover real-time insights at a glance with custom reporting
                  and dashboards.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="../images/low-cost.png"
                  alt="Expertise"
                  layout="constrained"
                  style={{ width: "60px", maxWidth: "60px" }}
                  loading="lazy"
                />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Drastic Cost <br />
                  Reduction
                </p>
                <p>
                  Cut costs and improve productivity by integrating and
                  automating processes.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid"
                  src="../images/clock.png"
                  alt="Expertise"
                  layout="constrained"
                  style={{ width: "60px", maxWidth: "60px" }}
                  loading="lazy"
                />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Faster <br />
                  Implementation
                </p>
                <p>
                  Get solutions that go live faster without operational
                  slowdowns or expensive mistakes.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="case-studies-container">
          <div id="case-studies" className="bg-indigo">
            <div className="container">
              <div className="intro text-center">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                      Case Studies
                    </p>
                    <h2 className=" my-2 text-white">
                      We Save Clients 1000s of Hours. Every Year.
                    </h2>
                    {/* <p className="text-white">
                      We helped a national healthcare provider save over 9,000
                      hours in weekly labor costs by developing a single
                      application...
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
          </div>
        </section>
        <section
          id="it-consulting"
          className="it-consulting"
          style={{ padding: "3rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12 mx-auto text-center">
                <h2 className="font-weight-medium">
                  Transform Your Procurement. Transform Your Business. Transform
                  Your Future.
                </h2>

                <p className=""></p>
              </div>
            </div>
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                marginTop: "3rem",
              }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3>Achieve Strategic Procurement</h3>
                <p className="mt-4">
                  Improve budgeting, reduce unapproved spending, and enforce
                  quality assurance at every step. We redesign and rebuild
                  procurement into a strategic process.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex img-fluid"
                  src="../images/new-images/checkmate.jpg"
                  alt="Solution Provider"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100 img-fluid"
                  src="../images/automation.jpg"
                  alt="Application Development"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                <h3>Automate the Entire Procurement Process</h3>
                <p className="mt-4">
                  Eliminate manual invoice processing, data entry errors, and
                  late fees. Break down bottlenecks. Remain agile no matter what
                  happens in your supply chain with procurement automation.
                </p>
              </div>
            </div>
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3>Improved Vendor Management</h3>
                <p className="mt-4">
                  Easily track, evaluate, and communicate with all your supplies
                  through one accessible platform. QA effortlessly. Evaluate and
                  issue contracts. Compare vendors and negotiate better.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="../images/new-images/jigsaw.jpg"
                  alt="System Integration"
                  layout="constrained"
                  style={{ height: "25rem" }}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100 img-fluid"
                  src="../images/new-images/truck.jpg"
                  alt="Application Development"
                  layout="constrained"
                  style={{ height: "25rem" }}
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 px-md-5 text-left-mobile">
                <h3>Reduce Risks to Your Supply Chain</h3>
                <p className="mt-4">
                  Keep your supply chain secure with cloud-based infrastructure.
                  Leverage data insights and increased transparency to stay
                  agile in an unpredictable world.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Testimonials />

        <section>
          <section
            className="bg-white"
            style={{
              padding: "5rem 0rem 5rem",
              marginTop: "0rem",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12  text-center">
                  <h2 className="mb-3 text-left-mobile">
                    Think Beyond Cost Reduction: Transform Your Procurement
                    Process
                  </h2>
                  <p className="text-dark text-left-mobile">
                    We’ll help you drastically reduce operational costs, missed
                    opportunities, and waste in your procurement process.
                  </p>
                  <Link
                    className="speak-to-btnx btn btn-primary btn-arrow mt-3"
                    to="/contact/"
                  >
                    Speak to a Technology Consultant
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
  }
}

export default ProcurementPage

export const procurementPageQuery = graphql`
  query procurementPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
